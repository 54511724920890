import React from 'react';
import PropTypes from "prop-types";

import { SectionWrapper } from './section.style';
import { Box, Container, Heading, Text } from '@components/index';

const PrivacyPolicy = ({ titleStyle, textStyle, sectionHeaderTitle }) => {
    return (
        <SectionWrapper>
            <Container>
                <Box mb="60px">
                    <Heading {...titleStyle} content="Privacy Policy" />
                    <Text {...textStyle} style={{ fontSize: "14px" }} content="Published on: 18/08/2020; Last modified on: 18/01/2021."></Text>
                </Box>
                <Box>
                    <p style={textStyle}>
                        Talent Swipe (“we”, “us” or “Company”) respects your privacy and are committed to protecting it through our compliance with this policy.
                    </p>
                    <p style={textStyle}>
                        This policy describes the types of information we may collect from you or that you may provide when you visit our website www.talentswipe.careers (the “Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
                    </p>
                    <p style={textStyle}>This policy applies solely to information we collect: </p>
                    <ul>
                        <li style={textStyle}>on this Website</li>
                        <li style={textStyle}>in e-mail, text, and other electronic messages between you and the Website </li>
                    </ul>
                    <p style={textStyle}>It does not apply to information collected by:</p>
                    <ul>
                        <li style={textStyle}>us offline or through any other means, including on any other website or application operated by us or any third party, or </li>
                        <li style={textStyle}>any third party, including through any application or content (including advertising) that may link to or be accessible or recommended from or on the Website  </li>
                    </ul>
                    <p style={textStyle}>
                        Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using the Website, you agree to this privacy policy. This policy may change from time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
                    </p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="What information do we collect about you and how do we collect it? " />
                    <p style={textStyle}>
                        We collect certain types of information from and about users of our Website, including information:
                    </p>
                    <ul>
                        <li style={textStyle}>by which you may be personally identified, such as name, address, age, e-mail address, date of birth, gender, phone number, social profiles, picture, studies, professional skills, hobbies, recommendations, experience in work, skills (“personal information”) </li>
                        <li style={textStyle}>that is about you but individually does not identify you, such as your internet protocol (IP) address and geolocation information; and </li>
                        <li style={textStyle}>about your internet connection, the equipment, operating system, and browser that you use to access the Website and usage details </li>
                    </ul>
                    <p style={textStyle}>We collect this information: </p>
                    <ul>
                        <li style={textStyle}>Directly from you when you interact in any way with us, apply for a job, submit a CV, apply with the LinkedIn profile or contact us by other means </li>
                        <li style={textStyle}>Automatically as you navigate through the Website. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies</li>
                    </ul>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="Why do we collect this information?" />
                    <p style={textStyle}>
                        We use information that we collect about you or that you provide to us, including any personal information:
                    </p>
                    <ul>
                        <li style={textStyle}>To match a job based on your experience</li>
                        <li style={textStyle}>To determine if you are the right person for a certain job</li>
                        <li style={textStyle}>To put you in contact with your potential employee</li>
                        <li style={textStyle}>To provide you with information or services that you request from us</li>
                        <li style={textStyle}>To optimize management and operation of the Website and give you a better experience</li>
                        <li style={textStyle}>To fulfill any other purpose for which you provide it</li>
                    </ul>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="For how long do we store your information?" />
                    <p style={textStyle}>
                        We store personal data only for the time necessary to fulfill the goals or until you withdraw your consent, ask for the deletion of the data, or the legitimate interest is no longer actual. After the end of the period, personal data will be destroyed or deleted from computer systems or converted to anonymous data for scientific, historical or statistical research purposes. Note that in certain situations that are explicitly regulated, we store the data for the period imposed by the law. We are automatically deleting personal data from our systems, if no activity was related to them in the past 12 months, and the data is older than 36 months. 
                    </p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="How we disclose your information?" />
                    <p style={textStyle}>
                        We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We do not share your personal information with others except as indicated within this policy or when we inform you and give you an opportunity to opt out of having your personal information shared. We may disclose personal information that we collect or that you provide as described in this privacy policy:
                    </p>
                    <ul>
                        <li style={textStyle}>To contractors, service providers, and other third parties we use to support our business</li>
                        <li style={textStyle}>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our Website users is among the assets transferred </li>
                        <li style={textStyle}>To fulfill the purpose for which you provide it </li>
                        <li style={textStyle}>For any other purpose disclosed by us when you provide the information </li>
                        <li style={textStyle}>With your consent </li>
                    </ul>
                    <p style={textStyle}>We may also disclose your personal information: </p>
                    <ul>
                        <li style={textStyle}>To comply with any court order, law, or legal process, including responding to any government or regulatory request </li>
                        <li style={textStyle}>To contractors located outside the EU if required</li>
                    </ul>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="What are your rights?" />
                    <ul>
                        <li style={textStyle}>You may access the personal information we have about you</li>
                        <li style={textStyle}>You may contact us to amend or update your personal information</li>
                        <li style={textStyle}>In certain situations, you may request that we erase or forget your personal data</li>
                    </ul>
                    <p style={textStyle}>If you would like to exercise any of the above rights, please contact us at privacy@talentswipe.careers. We will consider your request in accordance with applicable laws. To protect your privacy and security, we may take steps to verify your identity before complying with the request.</p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="Data security" />
                    <p style={textStyle}>
                        We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. The safety and security of your information also depends on you.
                    </p>
                    <p style={textStyle}>
                        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website and the Company is not and shall not be responsible for any loss or fraud as a result of such circumvention.
                    </p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="Changes to our Privacy Policy" />
                    <p style={textStyle}>
                        We may change this Privacy Policy at any time. All updates and changes to this Policy apply immediately upon notice, which we will make by displaying on the site and / or by email notification.
                    </p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="Contact us" />
                    <p style={textStyle}>
                        To ask questions or comment about this privacy policy and our privacy practices, contact us by email at <a href="mailto:privacy@talentswipe.careers">privacy@talentswipe.careers</a>.
                    </p>
                </Box>
            </Container>
        </SectionWrapper>
    )
}

PrivacyPolicy.propTypes = {
    sectionHeader: PropTypes.object,
    sectionHeaderTitle: PropTypes.object,
    titleStyle: PropTypes.object,
    textStyle: PropTypes.object,
};

PrivacyPolicy.defaultProps = {
    titleStyle: {
        fontSize: ["24px", "26px", "30px", "36px", "40px"],
        lineHeight: ["30px", "32px", "40px", "50px", "55px"],
        fontWeight: "700",
        color: "#32325d",
        letterSpacing: "-0.010em",
        mt: "40px",
    },
    textStyle: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#525f7f",
        lineHeight: "27px"
    },
    sectionHeader: {
        mb: "80px"
    },
    sectionHeaderTitle: {
        fontSize: ["16px", "16px", "18px", "20px", "20px"],
        fontFamily: "Poppins",
        fontWeight: "600",
        lineHeight: "27px",
        color: "#5268db",
        mb: "20px",
        mt: "60px"
    }
};

export default PrivacyPolicy;