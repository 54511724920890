import styled from 'styled-components';
import { deviceSize } from '../../../common/utils';

export const SectionWrapper = styled.section`
  padding: 120px 0 0px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  @media (max-width: 1440px) {
    padding: 80px 0 0px;
  }
  @media (max-width: ${deviceSize.laptop.px}) {
    min-height: 50vh;
  }
  @media (max-width: 480px) {
    padding: 80px 0 0px;
  }
  ul {
      margin-left: 20px
  }
  li {
    list-style-type: disc
  }
  button {
    border-radius: 6px;
    background-image: -moz-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -webkit-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    background-image: -ms-linear-gradient(
      29deg,
      rgb(255, 148, 147) 0%,
      rgb(255, 120, 162) 100%
    );
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
`;