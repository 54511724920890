import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'gatsby';

import { Box, Container, Text, Heading } from '@components/index';
import { SectionWrapper } from './section.style';

const CookiePolicy = ({ sectionHeaderTitle, titleStyle, textStyle }) => {
    return (
        <SectionWrapper>
            <Container>
                <Box mb="60px">
                    <Heading {...titleStyle} content="Cookie Policy" />
                    <Text {...textStyle} style={{ fontSize: "14px" }} content="Published on: 18/08/2020; Last modified on: 18/08/2020."></Text>
                </Box>
                <Box>
                    <p style={textStyle}>
                        We use technologies on the site www.talentswipe.careers (the Site) to collect information that helps us improve your online experience. In this Cookie Policy, we refer to these technologies, which include cookies, pixels, web beacons and gifs, collectively as cookies. This policy explains the different types of cookies used on the Site and how you can control them. We may change this Cookie Policy at any time. Please take a look at the last effective date at the top of this page to see when this Cookie Policy was last revised. Any changes in this Cookie Policy will become effective when we make the revised Cookie Policy available on or through the Site. Any personal information that we collect through the use of cookies is obtained through transparent notice to you and through your consent. Where applicable, we provide you with the opportunity to opt out. We hope that this Cookie Policy helps you understand, and feel more confident about, our use of cookies. Please also see our <Link to="/privacy-policy">Privacy Policy</Link> to understand the other ways in which we use information we collect about you. If you have any further queries, please contact us at <a href="mailto:privacy@talentswipe.careers">privacy@talentswipe.careers</a>.
                    </p>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="What is a cookie?" />
                    <Text content="Cookies are small text files that are stored on your computer or mobile device. They are widely used in order to make websites work, or work in a better, more efficient way. They can do this because websites can read and write these files, enabling them to recognise you and remember important information that will make your use of a website more convenient (e.g. by remembering your user preferences)." {...textStyle} />
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="What cookies do we use?" />
                    <p style={textStyle}>Below we list the different types of cookies we may use on the Site.</p>
                    <p style={textStyle}><b>Performance Cookies.</b> Performance cookies, which are sometimes called analytics cookies, collect information about your use of this Site and enable us to improve the way it works. For example, performance cookies show us which are the most frequently visited pages on the Site, allow us to see the overall patterns of usage on the Site, help us record any difficulties you have with the Site and show us whether our advertising is effective or not.</p>

                    <div style={{ border: "1px solid #EBEBEB", borderColor: 'gray', padding: "10px" }}>
                        <p style={textStyle}>Performance cookies on this Site are:</p>
                        <p style={textStyle}>
                            <b>_ga, _gat, _gid</b>: Set by Google Analytics to enable website owners to track visitor behaviour and measure site performance.
                            To opt out of Google Analytics, please visit the <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#cookiesSet" target="_blank" rel="noreferrer">Google Analytics site</a>.
                    </p>
                    </div>

                    <p style={textStyle}><b>Functionality Cookies.</b> In some circumstances, we may use functionality cookies. Functionality cookies allow us to remember the choices you make on our Site and to provide enhanced and more personalized features, such as customising a certain webpage. All of these features help us to improve your visit to the Site.</p>

                    <div style={{ border: "1px solid #EBEBEB", borderColor: 'gray', padding: "10px" }}>
                        <p style={textStyle}>Functional cookies on this Site are:</p>
                        <p style={textStyle}>
                            <b>_calendly_session</b>: Set by Calendly to allow the website to add events into the visitor&apos;s calendar if consent is given by the visitor.
                                For more information, please visit the <a href="https://calendly.com/pages/privacy#cookies-and-other-tracking-mechanisms" target="_blank" rel="noreferrer">Calendly Privacy page</a>.
                        </p>
                    </div>
                </Box>
                <Box>
                    <Heading {...sectionHeaderTitle} content="How do I control cookies?" />
                    <p style={textStyle}>
                        You may refuse to accept cookies from this Site at any time by activating the setting on your browser which allows you to refuse cookies. Further information about the procedure to follow in order to disable cookies can be found on your Internet browser provider&apos;s website via your help screen. You may wish to refer to <a href="http://www.allaboutcookies.org/manage-cookies/index.html" target="_blank" rel="noreferrer">http://www.allaboutcookies.org/manage-cookies/index.html</a> for information on commonly used browsers.

                        For more information about third party cookies and how to opt-out, please visit the third party privacy policy at the relevant link indicated in paragraphs above.

                        Please be aware that if cookies are disabled, not all features of the Site may operate as intended.
                    </p>
                </Box>
            </Container>
        </SectionWrapper>
    )
}

CookiePolicy.propTypes = {
    sectionHeader: PropTypes.object,
    sectionHeaderTitle: PropTypes.object,
    titleStyle: PropTypes.object,
    textStyle: PropTypes.object,
};

CookiePolicy.defaultProps = {
    titleStyle: {
        fontSize: ["24px", "26px", "30px", "36px", "40px"],
        lineHeight: ["30px", "32px", "40px", "50px", "55px"],
        fontWeight: "700",
        color: "#32325d",
        letterSpacing: "-0.010em",
        mt: "40px",
    },
    textStyle: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#525f7f",
        lineHeight: "27px"
    },
    sectionHeader: {
        mb: "80px"
    },
    sectionHeaderTitle: {
        fontSize: ["16px", "16px", "18px", "20px", "20px"],
        fontFamily: "Poppins",
        fontWeight: "600",
        lineHeight: "27px",
        color: "#5268db",
        mb: "20px",
        mt: "60px"
    }
};

export default CookiePolicy;